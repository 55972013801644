<template>
    <div aria-hidden="true" 
        class="avatar avatar-alphabet ratio ratio-1x1 rounded-circle bg-opacity-10"
        :class="{
            [`bg-${theme}`]: true,
            ['avatar-' + size]: true
        }">
        <span
            v-if="!imageUrl"
            class="d-flex justify-content-center align-items-center"
            :class="{
                [`text-${theme}`]: true,
                'caption': size === 'sm',
                'body-s': size === 'default',
                'body-l': size === 'lg',
                'heading-l': size === 'xl',
                'display-l': size === 'xxl',
                'display-xl': size === '3xl',
            }">
            {{ getInitials(text) }}
        </span>
        <img v-else :src="imageUrl" alt="Profile Picture">
    </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import { themeColors } from '../types';

defineProps({
    text: String,
    imageUrl: String as PropType<string|null>,
    size: {
        type: String as PropType<'sm'|'default'|'lg'|'xl'|'xxl'|'3xl'>,
        default: 'default'
    },
    theme: {
        type: String as PropType<themeColors>,
        default: 'primary'
    }
})

const getInitials = (x?: string) => x
    ? x.split(' ').slice(0, 2).map(x => x.replace(/\W/g, '').slice(0, 1)).join('').toUpperCase()
    : '';
</script>

<style lang="scss">
.avatar-alphabet {
    flex-shrink: 0;
    position: relative;
    height: 2.25rem;
    width: 2.25rem;
    text-align: center;
    overflow: hidden;
    
    &.avatar-sm {
        height: 1.75rem;
        width: 1.75rem;
    }

    &.avatar-lg {
        height: 3.5rem;
        width: 3.5rem;
    }

    &.avatar-xl {
        height: 4.5rem;
        width: 4.5rem;
    }

    &.avatar-xxl {
        height: 5.5rem;
        width: 5.5rem;
    }
    &.avatar-3xl {
        height: 6.5rem;
        width: 6.5rem;
    }
}
</style>